import React, { useEffect } from 'react'
import '../AboutUs.css'
import { useTranslation } from "react-i18next";
import fenceImg from '../../../Resources/Images/fence.webp'
import interior from '../../../Resources/Images/interior.webp'
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import storyImg from '../../../Resources/Images/story-img.webp'
import electroteam from '../../../Resources/Images/electricians-team-work.webp'
import Razlogi from '../../razlogi/Razlogi';
import { useNavigate } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);


const AboutComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        let ctx = gsap.context(() => {
            let timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: '.second-right',
                    start: 'top+=-60% center',
                    scrub: true,
                    markers: false,
                },
            });

            

            timeline.to('.storyy-img', { y: '-30%', ease: 'power1.out', });
           


            let timeline2 = gsap.timeline({
                scrollTrigger: {
                    trigger: '.third-left',
                    start: 'top+=-60% center',
                    scrub: true,
                    markers: false,
                },
            });

            timeline2.to('.team-slika', { y: '-30%', ease: 'power1.out', });


        });

        return () => ctx.revert();

    }, []);

    const handleContactClick = () => {
        window.scrollTo(0, 0);

        navigate('/contact');
    };

    return (
        <div className='about-main'>
            <div className='about-wrapper'>
                <div className='first-wrapper'>
                    <h1 className='about-us-header'>{t("NAŠA")} <span className='zgodba'>{t("ZGODBA")}</span></h1>
                    <p className='zgodba-paragraph'>{t("zgodbap")}</p>
                </div>
                <div className='second-wrapper'>
                    <div className='second-left'>
                        <h2 className='about-zacetek'>{t("zacetek")}</h2>
                        <p className='zacetek-pa'>{t("zacetekp1")}</p>
                        <p className='zacetek-ph'>{t("vizija")}</p>
                        <p className='zacetek-pa'>{t("vizijap")}</p>

                    </div>
                    <div className='second-right'>
                        {/*<img src={fenceImg} className='gsap-fence' alt='the-animated-fence' />*/}
                        {/*<img src={interior} className='interior-img' alt='the-animated-interior' />*/}
                        <img src={storyImg} className='storyy-img' alt='the-animated-story' />

                    </div>

                    <div className='second-left-hidden'>
                        <h2 className='about-zacetek'>{t("zacetek")}</h2>
                        <p className='zacetek-pa'>{t("zacetekp1")}</p>
                        <p className='zacetek-ph'>{t("vizija")}</p>
                        <p className='zacetek-pa'>{t("vizijap")}</p>

                    </div>

                </div>
                <div className='third-wrapper'>
                    <div className='third-left'>
                        <img className='team-slika' src={electroteam}/>
                    </div>
                    <div className='third-right'>
                        <h2 className='about-zacetek'>{t("gre")}</h2>
                        <p className='zacetek-pp'>{t("grep")}</p>
                        <p className='zacetek-pp'>{t("grep2")}</p>
                        <p className='zacetek-pp'>{t("grep3")}</p>
                        <p className='zacetek-pp'>{t("grep4")}</p>

                    </div>

                </div>
            </div>
            <h2 className='about-us-header razlogi-header'>{t("Razlogi")}</h2>
            <Razlogi/>
            <div className='super-button zdaj-button' onClick={handleContactClick}>{t("kontaktzdaj")}</div>

            <div className='tmd-div'></div>
        </div>
    )
}

export default AboutComponent
